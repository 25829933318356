import React, { useState, useContext } from "react";
import { AuthContext } from "../../App";

// css
import "./LoginPage.css";

// assets
import LoginImg from "../../assets/images/logo1-default.png";

const LoginPage = () => {
  const passURL = process.env.REACT_APP_PASS;

  const { dispatch } = useContext(AuthContext);

  const initialState = {
    password: "",
    isSubmitting: false,
    errorMessage: null,
  };

  const [data, setData] = useState(initialState);
  const [buttonText, setButtonText] = useState("LOG IN");

  const handleInputChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setButtonText("");
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
    const LoginInput = data.password;

    if (LoginInput === passURL) {
      sessionStorage.setItem("userpass", data.password);
      dispatch({ type: "LOGIN", payload: LoginInput });
    } else {
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: "Password failed. Please try again.",
      });
      setButtonText("LOG IN");
    }
  };

  const renderLoginButton = () => {
    if (buttonText === "LOG IN" || buttonText === "SUCCESS!") {
      return (
        <button disabled={data.isSubmitting} className="submit-button">
          {buttonText}
        </button>
      );
    } else {
      return (
        <button className="submit-button">
          <div className="login-loading-dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      );
    }
  };

  return (
    <div className="login-wrapper">
      <div className="logo-img-wrapper">
        <img src={LoginImg} alt="" className="login-logo" />
      </div>
      <form onSubmit={handleFormSubmit}>
        <label htmlFor="password">Enter Password: </label>
        <input
          type="password"
          label="password"
          value={data.password}
          onChange={handleInputChange}
          name="password"
        />
        {data.errorMessage && <p className="form-error">{data.errorMessage}</p>}
        <div className="login-button-wrapper">{renderLoginButton()}</div>
      </form>
    </div>
  );
};

export default LoginPage;
